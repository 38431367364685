* {
    box-sizing: border-box;
}

:root {
    --color-workflow-card-connector: var(--color-scale-gray-3);
    --color-workflow-card-connector-bg: var(--color-scale-gray-3);
    --color-workflow-card-connector-inactive: var(--borderColor-default, var(--color-border-default));
    --color-workflow-card-connector-inactive-bg: var(--borderColor-default, var(--color-border-default));
    --color-workflow-card-connector-highlight: var(--color-scale-blue-4);
    --color-workflow-card-connector-highlight-bg: var(--color-scale-blue-4);
    --color-workflow-card-bg: var(--color-scale-white);
    --color-workflow-card-inactive-bg: var(--bgColor-inset, var(--color-canvas-inset));
    --color-workflow-card-header-shadow: rgba(0, 0, 0, 0);
    --color-workflow-card-progress-complete-bg: var(--color-scale-blue-4);
    --color-workflow-card-progress-incomplete-bg: var(--color-scale-gray-2);
    --color-discussions-state-answered-icon: var(--color-scale-white);
    --color-bg-discussions-row-emoji-box: rgba(209, 213, 218, 0.5);
    --color-notifications-button-text: var(--fgColor-muted, var(--color-fg-muted));
    --color-notifications-button-hover-text: var(--fgColor-default, var(--color-fg-default));
    --color-notifications-button-hover-bg: var(--color-scale-gray-2);
    --color-notifications-row-read-bg: var(--bgColor-muted, var(--color-canvas-subtle));
    --color-notifications-row-bg: var(--color-scale-white);
    --color-icon-directory: var(--color-scale-blue-3);
    --color-checks-step-error-icon: var(--color-scale-red-4);
    --color-calendar-halloween-graph-day-L1-bg: #ffee4a;
    --color-calendar-halloween-graph-day-L2-bg: #ffc501;
    --color-calendar-halloween-graph-day-L3-bg: #fe9600;
    --color-calendar-halloween-graph-day-L4-bg: #03001c;
    --color-calendar-winter-graph-day-L1-bg: #B6E3FF;
    --color-calendar-winter-graph-day-L2-bg: #54AEFF;
    --color-calendar-winter-graph-day-L3-bg: #0969DA;
    --color-calendar-winter-graph-day-L4-bg: #0A3069;
    --color-calendar-graph-day-bg: #ebedf0;
    --color-calendar-graph-day-border: rgba(27, 31, 35, 0.06);
    --color-calendar-graph-day-L1-bg: #9be9a8;
    --color-calendar-graph-day-L2-bg: #40c463;
    --color-calendar-graph-day-L3-bg: #30a14e;
    --color-calendar-graph-day-L4-bg: #216e39;
    --color-calendar-graph-day-L1-border: rgba(27, 31, 35, 0.06);
    --color-calendar-graph-day-L2-border: rgba(27, 31, 35, 0.06);
    --color-calendar-graph-day-L3-border: rgba(27, 31, 35, 0.06);
    --color-calendar-graph-day-L4-border: rgba(27, 31, 35, 0.06);
    --color-user-mention-fg: var(--fgColor-default, var(--color-fg-default));
    --color-user-mention-bg: var(--bgColor-attention-muted, var(--color-attention-subtle));
    --color-dashboard-feed-bg: var(--color-scale-white);
    --color-mktg-btn-shadow-outline: rgba(0, 0, 0, 0.15) 0 0 0 1px inset;
    --color-marketing-icon-primary: var(--color-scale-blue-4);
    --color-marketing-icon-secondary: var(--color-scale-blue-3);
    --color-project-header-bg: var(--color-scale-gray-9);
    --color-project-sidebar-bg: var(--color-scale-white);
    --color-project-gradient-in: var(--color-scale-white);
    --color-project-gradient-out: rgba(255, 255, 255, 0);
    --color-diff-blob-selected-line-highlight-mix-blend-mode: multiply;
    --color-checks-donut-error: var(--color-scale-red-4);
    --color-checks-donut-pending: var(--color-scale-yellow-4);
    --color-checks-donut-success: var(--fgColor-success, var(--color-success-emphasis));
    --color-checks-donut-neutral: var(--color-scale-gray-3);
    --color-text-white: var(--color-scale-white);
    --color-social-reaction-bg-hover: var(--color-scale-gray-1);
    --color-social-reaction-bg-reacted-hover: var(--color-scale-blue-1);
    --topicTag-borderColor: #ffffff00;
    --highlight-neutral-bgColor: #fff8c5;
    --page-header-bgColor: #f6f8fa;
    --diffBlob-addition-fgColor-text: #1f2328;
    --diffBlob-addition-fgColor-num: #1f2328;
    --diffBlob-addition-bgColor-num: #d1f8d9;
    --diffBlob-addition-bgColor-line: #dafbe1;
    --diffBlob-addition-bgColor-word: #aceebb;
    --diffBlob-deletion-fgColor-text: #1f2328;
    --diffBlob-deletion-fgColor-num: #1f2328;
    --diffBlob-deletion-bgColor-num: #ffcecb;
    --diffBlob-deletion-bgColor-line: #ffebe9;
    --diffBlob-deletion-bgColor-word: #ff818266;
    --diffBlob-hunk-bgColor-num: #54aeff66;
    --diffBlob-expander-iconColor: #636c76;
    --codeMirror-fgColor: #1f2328;
    --codeMirror-bgColor: #ffffff;
    --codeMirror-gutters-bgColor: #ffffff;
    --codeMirror-gutterMarker-fgColor-default: #ffffff;
    --codeMirror-gutterMarker-fgColor-muted: #636c76;
    --codeMirror-lineNumber-fgColor: #636c76;
    --codeMirror-cursor-fgColor: #1f2328;
    --codeMirror-selection-bgColor: #54aeff66;
    --codeMirror-activeline-bgColor: #afb8c133;
    --codeMirror-matchingBracket-fgColor: #1f2328;
    --codeMirror-lines-bgColor: #ffffff;
    --codeMirror-syntax-fgColor-comment: #24292f;
    --codeMirror-syntax-fgColor-constant: #0550ae;
    --codeMirror-syntax-fgColor-entity: #8250df;
    --codeMirror-syntax-fgColor-keyword: #cf222e;
    --codeMirror-syntax-fgColor-storage: #cf222e;
    --codeMirror-syntax-fgColor-string: #0a3069;
    --codeMirror-syntax-fgColor-support: #0550ae;
    --codeMirror-syntax-fgColor-variable: #953800;
    --header-fgColor-default: #ffffffb3;
    --header-fgColor-logo: #ffffff;
    --header-bgColor: #24292f;
    --header-borderColor-divider: #57606a;
    --headerSearch-bgColor: #24292f;
    --headerSearch-borderColor: #57606a;
    --data-blue-color: #006edb;
    --data-auburn-color: #9d615c;
    --data-orange-color: #eb670f;
    --data-yellow-color: #b88700;
    --data-green-color: #30a147;
    --data-teal-color: #179b9b;
    --data-purple-color: #894ceb;
    --data-pink-color: #ce2c85;
    --data-red-color: #df0c24;
    --data-gray-color: #808fa3;
    --display-blue-bgColor-muted: #d1f0ff;
    --display-blue-bgColor-emphasis: #006edb;
    --display-blue-fgColor: #005fcc;
    --display-blue-borderColor-muted: #ade1ff;
    --display-blue-borderColor-emphasis: #006edb;
    --display-green-bgColor-muted: #caf7ca;
    --display-green-bgColor-emphasis: #2c8141;
    --display-green-fgColor: #2b6e3f;
    --display-green-borderColor-muted: #9ceda0;
    --display-green-borderColor-emphasis: #2c8141;
    --display-orange-bgColor-muted: #ffe7d1;
    --display-orange-bgColor-emphasis: #b8500f;
    --display-orange-fgColor: #a24610;
    --display-orange-borderColor-muted: #fecfaa;
    --display-orange-borderColor-emphasis: #b8500f;
    --display-purple-bgColor-muted: #f1e5ff;
    --display-purple-bgColor-emphasis: #894ceb;
    --display-purple-fgColor: #783ae4;
    --display-purple-borderColor-muted: #e6d2fe;
    --display-purple-borderColor-emphasis: #894ceb;
    --display-red-bgColor-muted: #ffe2e0;
    --display-red-bgColor-emphasis: #df0c24;
    --display-red-fgColor: #c50d28;
    --display-red-borderColor-muted: #fecdcd;
    --display-red-borderColor-emphasis: #df0c24;
    --display-yellow-bgColor-muted: #ffec9e;
    --display-yellow-bgColor-emphasis: #946a00;
    --display-yellow-fgColor: #805900;
    --display-yellow-borderColor-muted: #ffd642;
    --display-yellow-borderColor-emphasis: #946a00;
    --display-gray-bgColor-muted: #e8ecf2;
    --display-gray-bgColor-emphasis: #647182;
    --display-gray-fgColor: #5c6570;
    --display-gray-borderColor-muted: #d2dae4;
    --display-gray-borderColor-emphasis: #647182;
    --display-auburn-bgColor-muted: #f2e9e9;
    --display-auburn-bgColor-emphasis: #9d615c;
    --display-auburn-fgColor: #8a5551;
    --display-auburn-borderColor-muted: #e6d6d5;
    --display-auburn-borderColor-emphasis: #9d615c;
    --display-brown-bgColor-muted: #eeeae2;
    --display-brown-bgColor-emphasis: #856d4c;
    --display-brown-fgColor: #755f43;
    --display-brown-borderColor-muted: #dfd7c8;
    --display-brown-borderColor-emphasis: #856d4c;
    --display-lemon-bgColor-muted: #f7eea1;
    --display-lemon-bgColor-emphasis: #866e04;
    --display-lemon-fgColor: #786002;
    --display-lemon-borderColor-muted: #f0db3d;
    --display-lemon-borderColor-emphasis: #866e04;
    --display-olive-bgColor-muted: #f0f0ad;
    --display-olive-bgColor-emphasis: #64762d;
    --display-olive-fgColor: #56682c;
    --display-olive-borderColor-muted: #dbe170;
    --display-olive-borderColor-emphasis: #64762d;
    --display-lime-bgColor-muted: #e3f2b5;
    --display-lime-bgColor-emphasis: #527a29;
    --display-lime-fgColor: #476c28;
    --display-lime-borderColor-muted: #c7e580;
    --display-lime-borderColor-emphasis: #527a29;
    --display-pine-bgColor-muted: #bff8db;
    --display-pine-bgColor-emphasis: #167e53;
    --display-pine-fgColor: #156f4b;
    --display-pine-borderColor-muted: #80efb9;
    --display-pine-borderColor-emphasis: #167e53;
    --display-teal-bgColor-muted: #c7f5ef;
    --display-teal-bgColor-emphasis: #127e81;
    --display-teal-fgColor: #106e75;
    --display-teal-borderColor-muted: #89ebe1;
    --display-teal-borderColor-emphasis: #127e81;
    --display-cyan-bgColor-muted: #bdf4ff;
    --display-cyan-bgColor-emphasis: #007b94;
    --display-cyan-fgColor: #006a80;
    --display-cyan-borderColor-muted: #7ae9ff;
    --display-cyan-borderColor-emphasis: #007b94;
    --display-indigo-bgColor-muted: #e5e9ff;
    --display-indigo-bgColor-emphasis: #5a61e7;
    --display-indigo-fgColor: #494edf;
    --display-indigo-borderColor-muted: #d2d7fe;
    --display-indigo-borderColor-emphasis: #5a61e7;
    --avatar-bgColor: #ffffff;
    --avatar-borderColor: #1f232826;
    --avatar-shadow: 0px 0px 0px 2px #ffffffcc;
    --avatarStack-fade-bgColor-default: #afb8c1;
    --avatarStack-fade-bgColor-muted: #d0d7de;
    --control-bgColor-rest: #f6f8fa;
    --control-bgColor-hover: #eef1f4;
    --control-bgColor-active: #e7ebef;
    --control-bgColor-disabled: #eaeef2b3;
    --control-bgColor-selected: #f6f8fa;
    --control-fgColor-rest: #24292f;
    --control-fgColor-placeholder: #6e7781;
    --control-fgColor-disabled: #8c959f;
    --control-borderColor-rest: #d0d7de;
    --control-borderColor-emphasis: #868f99;
    --control-borderColor-disabled: #eaeef2b3;
    --control-borderColor-selected: #f6f8fa;
    --control-borderColor-success: #1a7f37;
    --control-borderColor-danger: #cf222e;
    --control-borderColor-warning: #bf8700;
    --control-iconColor-rest: #636c76;
    --control-transparent-bgColor-rest: #ffffff00;
    --control-transparent-bgColor-hover: #d0d7de33;
    --control-transparent-bgColor-active: #d0d7de66;
    --control-transparent-bgColor-disabled: #eaeef2b3;
    --control-transparent-bgColor-selected: #d0d7de33;
    --control-transparent-borderColor-rest: #ffffff00;
    --control-transparent-borderColor-hover: #ffffff00;
    --control-transparent-borderColor-active: #ffffff00;
    --control-danger-fgColor-rest: #d1242f;
    --control-danger-fgColor-hover: #d1242f;
    --control-danger-bgColor-hover: #ffebe9;
    --control-danger-bgColor-active: #ffebe966;
    --control-checked-bgColor-rest: #0969da;
    --control-checked-bgColor-hover: #0860ca;
    --control-checked-bgColor-active: #0757ba;
    --control-checked-bgColor-disabled: #8c959f;
    --control-checked-fgColor-rest: #ffffff;
    --control-checked-fgColor-disabled: #ffffff;
    --control-checked-borderColor-rest: #0969da;
    --control-checked-borderColor-hover: #0860ca;
    --control-checked-borderColor-active: #0757ba;
    --control-checked-borderColor-disabled: #8c959f;
    --controlTrack-bgColor-rest: #eaeef2;
    --controlTrack-bgColor-hover: #dee3e8;
    --controlTrack-bgColor-active: #d2d8de;
    --controlTrack-bgColor-disabled: #8c959f;
    --controlTrack-fgColor-rest: #636c76;
    --controlTrack-fgColor-disabled: #ffffff;
    --controlTrack-borderColor-rest: #ffffff00;
    --controlTrack-borderColor-disabled: #8c959f;
    --controlKnob-bgColor-rest: #ffffff;
    --controlKnob-bgColor-disabled: #eaeef2b3;
    --controlKnob-bgColor-checked: #ffffff;
    --controlKnob-borderColor-rest: #868f99;
    --controlKnob-borderColor-disabled: #eaeef2b3;
    --controlKnob-borderColor-checked: #0969da;
    --counter-borderColor: #ffffff00;
    --button-default-fgColor-rest: #24292f;
    --button-default-bgColor-rest: #f6f8fa;
    --button-default-bgColor-hover: #eef1f4;
    --button-default-bgColor-active: #e7ebef;
    --button-default-bgColor-selected: #e7ebef;
    --button-default-bgColor-disabled: #eaeef2b3;
    --button-default-borderColor-rest: #d0d7de;
    --button-default-borderColor-hover: #d0d7de;
    --button-default-borderColor-active: #d0d7de;
    --button-default-borderColor-disabled: #eaeef2b3;
    --button-default-shadow-resting: 0px 1px 0px 0px #1f23280a;
    --button-primary-fgColor-rest: #ffffff;
    --button-primary-fgColor-disabled: #ffffffcc;
    --button-primary-iconColor-rest: #ffffffcc;
    --button-primary-bgColor-rest: #1f883d;
    --button-primary-bgColor-hover: #1c8139;
    --button-primary-bgColor-active: #197935;
    --button-primary-bgColor-disabled: #95d8a6;
    --button-primary-borderColor-rest: #1f232826;
    --button-primary-borderColor-hover: #1f232826;
    --button-primary-borderColor-active: #1f232826;
    --button-primary-borderColor-disabled: #95d8a6;
    --button-primary-shadow-selected: inset 0px 1px 0px 0px #002d114d;
    --button-invisible-fgColor-rest: #0969da;
    --button-invisible-fgColor-hover: #0969da;
    --button-invisible-fgColor-disabled: #8c959f;
    --button-invisible-iconColor-rest: #636c76;
    --button-invisible-iconColor-hover: #636c76;
    --button-invisible-iconColor-disabled: #8c959f;
    --button-invisible-bgColor-rest: #ffffff00;
    --button-invisible-bgColor-hover: #d0d7de33;
    --button-invisible-bgColor-active: #d0d7de66;
    --button-invisible-bgColor-disabled: #eaeef2b3;
    --button-invisible-borderColor-rest: #ffffff00;
    --button-invisible-borderColor-hover: #ffffff00;
    --button-invisible-borderColor-disabled: #eaeef2b3;
    --button-outline-fgColor-rest: #0969da;
    --button-outline-fgColor-hover: #ffffff;
    --button-outline-fgColor-active: #ffffff;
    --button-outline-fgColor-disabled: #0969da80;
    --button-outline-bgColor-rest: #f6f8fa;
    --button-outline-bgColor-hover: #0969da;
    --button-outline-bgColor-active: #0757ba;
    --button-outline-bgColor-disabled: #f6f8fa;
    --button-outline-borderColor-hover: #1f232826;
    --button-outline-borderColor-active: #1f232826;
    --button-outline-shadow-selected: inset 0px 1px 0px 0px #00215533;
    --button-danger-fgColor-rest: #d1242f;
    --button-danger-fgColor-hover: #ffffff;
    --button-danger-fgColor-active: #ffffff;
    --button-danger-fgColor-disabled: #d1242f80;
    --button-danger-iconColor-rest: #d1242f;
    --button-danger-iconColor-hover: #ffffff;
    --button-danger-bgColor-rest: #f6f8fa;
    --button-danger-bgColor-hover: #a40e26;
    --button-danger-bgColor-active: #8b0820;
    --button-danger-bgColor-disabled: #eaeef2b3;
    --button-danger-borderColor-rest: #d0d7de;
    --button-danger-borderColor-hover: #1f232826;
    --button-danger-borderColor-active: #1f232826;
    --button-danger-shadow-selected: inset 0px 1px 0px 0px #4c001433;
    --button-inactive-fgColor: #57606a;
    --button-inactive-bgColor: #eaeef2;
    --button-star-iconColor: #eac54f;
    --buttonCounter-default-bgColor-rest: #afb8c133;
    --buttonCounter-invisible-bgColor-rest: #afb8c133;
    --buttonCounter-primary-bgColor-rest: #002d1133;
    --buttonCounter-outline-bgColor-rest: #0969da1a;
    --buttonCounter-outline-bgColor-hover: #ffffff33;
    --buttonCounter-outline-bgColor-disabled: #0969da0d;
    --buttonCounter-outline-fgColor-rest: #0550ae;
    --buttonCounter-outline-fgColor-hover: #ffffff;
    --buttonCounter-outline-fgColor-disabled: #0969da80;
    --buttonCounter-danger-bgColor-hover: #ffffff33;
    --buttonCounter-danger-bgColor-disabled: #cf222e0d;
    --buttonCounter-danger-bgColor-rest: #cf222e1a;
    --buttonCounter-danger-fgColor-rest: #c21c2c;
    --buttonCounter-danger-fgColor-hover: #ffffff;
    --buttonCounter-danger-fgColor-disabled: #d1242f80;
    --focus-outlineColor: #0969da;
    --focus-outline: #0969da solid 2px;
    --menu-bgColor-active: #ffffff00;
    --overlay-bgColor: #ffffff;
    --overlay-borderColor: #d0d7de80;
    --overlay-backdrop-bgColor: #8c959f33;
    --selectMenu-borderColor: #ffffff00;
    --selectMenu-bgColor-active: #b6e3ff;
    --sideNav-bgColor-selected: #ffffff;
    --skeletonLoader-bgColor: #f6f8fa;
    --timelineBadge-bgColor: #eaeef2;
    --treeViewItem-leadingVisual-iconColor-rest: #54aeff;
    --underlineNav-borderColor-active: #fd8c73;
    --underlineNav-borderColor-hover: #afb8c133;
    --underlineNav-iconColor-rest: #636c76;
    --selection-bgColor: #0969da33;
    --fgColor-default: #1f2328;
    --fgColor-muted: #636c76;
    --fgColor-onEmphasis: #ffffff;
    --fgColor-white: #ffffff;
    --fgColor-black: #1f2328;
    --fgColor-disabled: #8c959f;
    --fgColor-link: #0969da;
    --fgColor-neutral: #6e7781;
    --fgColor-accent: #0969da;
    --fgColor-success: #1a7f37;
    --fgColor-attention: #9a6700;
    --fgColor-severe: #bc4c00;
    --fgColor-danger: #d1242f;
    --fgColor-open: #1a7f37;
    --fgColor-closed: #d1242f;
    --fgColor-done: #8250df;
    --fgColor-sponsors: #bf3989;
    --bgColor-default: #ffffff;
    --bgColor-muted: #f6f8fa;
    --bgColor-inset: #f6f8fa;
    --bgColor-emphasis: #24292f;
    --bgColor-inverse: #24292f;
    --bgColor-white: #ffffff;
    --bgColor-black: #1f2328;
    --bgColor-disabled: #eaeef2b3;
    --bgColor-transparent: #ffffff00;
    --bgColor-neutral-muted: #afb8c133;
    --bgColor-neutral-emphasis: #6e7781;
    --bgColor-accent-muted: #ddf4ff;
    --bgColor-accent-emphasis: #0969da;
    --bgColor-success-muted: #dafbe1;
    --bgColor-success-emphasis: #1f883d;
    --bgColor-attention-muted: #fff8c5;
    --bgColor-attention-emphasis: #9a6700;
    --bgColor-severe-muted: #fff1e5;
    --bgColor-severe-emphasis: #bc4c00;
    --bgColor-danger-muted: #ffebe9;
    --bgColor-danger-emphasis: #cf222e;
    --bgColor-open-muted: #dafbe1;
    --bgColor-open-emphasis: #1f883d;
    --bgColor-closed-muted: #ffebe9;
    --bgColor-closed-emphasis: #cf222e;
    --bgColor-done-muted: #fbefff;
    --bgColor-done-emphasis: #8250df;
    --bgColor-sponsors-muted: #ffeff7;
    --bgColor-sponsors-emphasis: #bf3989;
    --borderColor-default: #d0d7de;
    --borderColor-muted: #d0d7deb3;
    --borderColor-emphasis: #6e7781;
    --borderColor-disabled: #eaeef2b3;
    --borderColor-transparent: #ffffff00;
    --borderColor-neutral-muted: #afb8c133;
    --borderColor-neutral-emphasis: #6e7781;
    --borderColor-accent-muted: #54aeff66;
    --borderColor-accent-emphasis: #0969da;
    --borderColor-success-muted: #4ac26b66;
    --borderColor-success-emphasis: #1a7f37;
    --borderColor-attention-muted: #d4a72c66;
    --borderColor-attention-emphasis: #bf8700;
    --borderColor-severe-muted: #fb8f4466;
    --borderColor-severe-emphasis: #bc4c00;
    --borderColor-danger-muted: #ff818266;
    --borderColor-danger-emphasis: #cf222e;
    --borderColor-open-muted: #4ac26b66;
    --borderColor-open-emphasis: #1a7f37;
    --borderColor-closed-muted: #ff818266;
    --borderColor-closed-emphasis: #cf222e;
    --borderColor-done-muted: #c297ff66;
    --borderColor-done-emphasis: #8250df;
    --borderColor-sponsors-muted: #ff80c866;
    --borderColor-sponsors-emphasis: #bf3989;
    --color-ansi-black: #24292f;
    --color-ansi-black-bright: #57606a;
    --color-ansi-white: #6e7781;
    --color-ansi-white-bright: #8c959f;
    --color-ansi-gray: #6e7781;
    --color-ansi-red: #cf222e;
    --color-ansi-red-bright: #a40e26;
    --color-ansi-green: #116329;
    --color-ansi-green-bright: #1a7f37;
    --color-ansi-yellow: #4d2d00;
    --color-ansi-yellow-bright: #633c01;
    --color-ansi-blue: #0969da;
    --color-ansi-blue-bright: #218bff;
    --color-ansi-magenta: #8250df;
    --color-ansi-magenta-bright: #a475f9;
    --color-ansi-cyan: #1b7c83;
    --color-ansi-cyan-bright: #3192aa;
    --color-prettylights-syntax-comment: #57606a;
    --color-prettylights-syntax-constant: #0550ae;
    --color-prettylights-syntax-constant-other-reference-link: #0a3069;
    --color-prettylights-syntax-entity: #6639ba;
    --color-prettylights-syntax-storage-modifier-import: #24292f;
    --color-prettylights-syntax-entity-tag: #0550ae;
    --color-prettylights-syntax-keyword: #cf222e;
    --color-prettylights-syntax-string: #0a3069;
    --color-prettylights-syntax-variable: #953800;
    --color-prettylights-syntax-brackethighlighter-unmatched: #82071e;
    --color-prettylights-syntax-brackethighlighter-angle: #57606a;
    --color-prettylights-syntax-invalid-illegal-text: #f6f8fa;
    --color-prettylights-syntax-invalid-illegal-bg: #82071e;
    --color-prettylights-syntax-carriage-return-text: #f6f8fa;
    --color-prettylights-syntax-carriage-return-bg: #cf222e;
    --color-prettylights-syntax-string-regexp: #116329;
    --color-prettylights-syntax-markup-list: #3b2300;
    --color-prettylights-syntax-markup-heading: #0550ae;
    --color-prettylights-syntax-markup-italic: #24292f;
    --color-prettylights-syntax-markup-bold: #24292f;
    --color-prettylights-syntax-markup-deleted-text: #82071e;
    --color-prettylights-syntax-markup-deleted-bg: #ffebe9;
    --color-prettylights-syntax-markup-inserted-text: #116329;
    --color-prettylights-syntax-markup-inserted-bg: #dafbe1;
    --color-prettylights-syntax-markup-changed-text: #953800;
    --color-prettylights-syntax-markup-changed-bg: #ffd8b5;
    --color-prettylights-syntax-markup-ignored-text: #eaeef2;
    --color-prettylights-syntax-markup-ignored-bg: #0550ae;
    --color-prettylights-syntax-meta-diff-range: #8250df;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #8c959f;
    --color-scale-black: #1f2328;
    --color-scale-transparent: #ffffff00;
    --color-scale-white: #ffffff;
    --color-scale-gray-0: #f6f8fa;
    --color-scale-gray-1: #eaeef2;
    --color-scale-gray-2: #d0d7de;
    --color-scale-gray-3: #afb8c1;
    --color-scale-gray-4: #8c959f;
    --color-scale-gray-5: #6e7781;
    --color-scale-gray-6: #57606a;
    --color-scale-gray-7: #424a53;
    --color-scale-gray-8: #32383f;
    --color-scale-gray-9: #24292f;
    --color-scale-blue-0: #ddf4ff;
    --color-scale-blue-1: #b6e3ff;
    --color-scale-blue-2: #80ccff;
    --color-scale-blue-3: #54aeff;
    --color-scale-blue-4: #218bff;
    --color-scale-blue-5: #0969da;
    --color-scale-blue-6: #0550ae;
    --color-scale-blue-7: #033d8b;
    --color-scale-blue-8: #0a3069;
    --color-scale-blue-9: #002155;
    --color-scale-green-0: #dafbe1;
    --color-scale-green-1: #aceebb;
    --color-scale-green-2: #6fdd8b;
    --color-scale-green-3: #4ac26b;
    --color-scale-green-4: #2da44e;
    --color-scale-green-5: #1a7f37;
    --color-scale-green-6: #116329;
    --color-scale-green-7: #044f1e;
    --color-scale-green-8: #003d16;
    --color-scale-green-9: #002d11;
    --color-scale-yellow-0: #fff8c5;
    --color-scale-yellow-1: #fae17d;
    --color-scale-yellow-2: #eac54f;
    --color-scale-yellow-3: #d4a72c;
    --color-scale-yellow-4: #bf8700;
    --color-scale-yellow-5: #9a6700;
    --color-scale-yellow-6: #7d4e00;
    --color-scale-yellow-7: #633c01;
    --color-scale-yellow-8: #4d2d00;
    --color-scale-yellow-9: #3b2300;
    --color-scale-orange-0: #fff1e5;
    --color-scale-orange-1: #ffd8b5;
    --color-scale-orange-2: #ffb77c;
    --color-scale-orange-3: #fb8f44;
    --color-scale-orange-4: #e16f24;
    --color-scale-orange-5: #bc4c00;
    --color-scale-orange-6: #953800;
    --color-scale-orange-7: #762c00;
    --color-scale-orange-8: #5c2200;
    --color-scale-orange-9: #471700;
    --color-scale-red-0: #ffebe9;
    --color-scale-red-1: #ffcecb;
    --color-scale-red-2: #ffaba8;
    --color-scale-red-3: #ff8182;
    --color-scale-red-4: #fa4549;
    --color-scale-red-5: #cf222e;
    --color-scale-red-6: #a40e26;
    --color-scale-red-7: #82071e;
    --color-scale-red-8: #660018;
    --color-scale-red-9: #4c0014;
    --color-scale-purple-0: #fbefff;
    --color-scale-purple-1: #ecd8ff;
    --color-scale-purple-2: #d8b9ff;
    --color-scale-purple-3: #c297ff;
    --color-scale-purple-4: #a475f9;
    --color-scale-purple-5: #8250df;
    --color-scale-purple-6: #6639ba;
    --color-scale-purple-7: #512a97;
    --color-scale-purple-8: #3e1f79;
    --color-scale-purple-9: #2e1461;
    --color-scale-pink-0: #ffeff7;
    --color-scale-pink-1: #ffd3eb;
    --color-scale-pink-2: #ffadda;
    --color-scale-pink-3: #ff80c8;
    --color-scale-pink-4: #e85aad;
    --color-scale-pink-5: #bf3989;
    --color-scale-pink-6: #99286e;
    --color-scale-pink-7: #772057;
    --color-scale-pink-8: #611347;
    --color-scale-pink-9: #4d0336;
    --color-scale-coral-0: #fff0eb;
    --color-scale-coral-1: #ffd6cc;
    --color-scale-coral-2: #ffb4a1;
    --color-scale-coral-3: #fd8c73;
    --color-scale-coral-4: #ec6547;
    --color-scale-coral-5: #c4432b;
    --color-scale-coral-6: #9e2f1c;
    --color-scale-coral-7: #801f0f;
    --color-scale-coral-8: #691105;
    --color-scale-coral-9: #510901;
    --shadow-inset: inset 0px 1px 0px 0px #1f23280a;
    --shadow-resting-xsmall: 0px 1px 0px 0px #1f23281a;
    --shadow-resting-small: 0px 1px 0px 0px #1f23280a;
    --shadow-resting-medium: 0px 3px 6px 0px #424a531f;
    --shadow-floating-small: 0px 0px 0px 1px #d0d7de80, 0px 6px 12px -3px #424a530a, 0px 6px 18px 0px #424a531f;
    --shadow-floating-medium: 0px 0px 0px 1px #d0d7de, 0px 8px 16px -4px #424a5314, 0px 4px 32px -4px #424a5314, 0px 24px 48px -12px #424a5314, 0px 48px 96px -24px #424a5314;
    --shadow-floating-large: 0px 0px 0px 1px #d0d7de, 0px 40px 80px 0px #424a533d;
    --shadow-floating-xlarge: 0px 0px 0px 1px #d0d7de, 0px 56px 112px 0px #424a5352;
    --shadow-floating-legacy: 0px 6px 12px -3px #424a530a, 0px 6px 18px 0px #424a531f;
    --line-number-cell-width: 40px;
    --diff-line-minimum-height: 24px;
    --duration-fast: 80ms;
    --easing-easeInOut: cubic-bezier(0.65,0,0.35,1);
    --actionListContent-paddingBlock: var(--control-medium-paddingBlock, 0.375rem);
    --Layout-pane-width: 220px;
    --Layout-content-width: 100%;
    --Layout-template-columns: 1fr var(--Layout-pane-width);
    --Layout-template-areas: "content pane";
    --Layout-column-gap: 16px;
    --Layout-row-gap: 16px;
    --Layout-outer-spacing-x: 0px;
    --Layout-outer-spacing-y: 0px;
    --Layout-inner-spacing-min: 0px;
    --Layout-inner-spacing-max: 0px;
    --h00-size-mobile: 2.5rem;
    --h0-size-mobile: 2rem;
    --h1-size-mobile: 1.625rem;
    --h2-size-mobile: 1.375rem;
    --h3-size-mobile: 1.125rem;
    --h00-size: 3rem;
    --h0-size: 2.5rem;
    --h1-size: 2rem;
    --h2-size: 1.5rem;
    --h3-size: 1.25rem;
    --h4-size: 1rem;
    --h5-size: 0.875rem;
    --h6-size: 0.75rem;
    --body-font-size: 0.875rem;
    --font-size-small: 0.75rem;
    --text-codeInline-size: 0.9285em;
    --text-codeBlock-lineHeight: 1.5385;
    --text-codeBlock-size: 0.8125rem;
    --text-caption-lineHeight: 1.3333;
    --text-caption-size: 0.75rem;
    --text-body-lineHeight-small: 1.6666;
    --text-body-lineHeight-medium: 1.4285;
    --text-body-lineHeight-large: 1.5;
    --text-body-size-small: 0.75rem;
    --text-body-size-medium: 0.875rem;
    --text-body-size-large: 1rem;
    --text-subtitle-lineHeight: 1.6;
    --text-subtitle-size: 1.25rem;
    --text-title-lineHeight-small: 1.5;
    --text-title-lineHeight-medium: 1.6;
    --text-title-lineHeight-large: 1.5;
    --text-title-size-small: 1rem;
    --text-title-size-medium: 1.25rem;
    --text-title-size-large: 2rem;
    --text-display-lineHeight: 1.4;
    --text-display-size: 2.5rem;
    --text-display-lineBoxHeight: 3.5rem;
    --fontStack-monospace: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
    --fontStack-sansSerif: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Noto Sans', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
    --fontStack-system: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Noto Sans', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
    --text-codeInline-weight: 400;
    --text-codeBlock-weight: 400;
    --text-caption-weight: 400;
    --text-body-weight: 400;
    --text-subtitle-weight: 400;
    --text-title-weight-small: 600;
    --text-title-weight-medium: 600;
    --text-title-weight-large: 600;
    --text-display-weight: 500;
    --text-codeInline-shorthand: var(--text-codeInline-weight) var(--text-codeInline-size) var(--fontStack-monospace);
    --text-codeBlock-shorthand: var(--text-codeBlock-weight) var(--text-codeBlock-size) / var(--text-codeBlock-lineHeight) var(--fontStack-monospace);
    --text-caption-shorthand: var(--text-caption-weight) var(--text-caption-size) / var(--text-caption-lineHeight) var(--fontStack-sansSerif);
    --text-body-shorthand-small: var(--text-body-weight) var(--text-body-size-small) / var(--text-body-lineHeight-small) var(--fontStack-sansSerif);
    --text-body-shorthand-medium: var(--text-body-weight) var(--text-body-size-medium) / var(--text-body-lineHeight-medium) var(--fontStack-sansSerif);
    --text-body-shorthand-large: var(--text-body-weight) var(--text-body-size-large) / var(--text-body-lineHeight-large) var(--fontStack-sansSerif);
    --text-subtitle-shorthand: var(--text-subtitle-weight) var(--text-subtitle-size) / var(--text-subtitle-lineHeight) var(--fontStack-sansSerif);
    --text-title-shorthand-small: var(--text-title-weight-small) var(--text-title-size-small) / var(--text-title-lineHeight-small) var(--fontStack-sansSerif);
    --text-title-shorthand-medium: var(--text-title-weight-medium) var(--text-title-size-medium) / var(--text-title-lineHeight-medium) var(--fontStack-sansSerif);
    --text-title-shorthand-large: var(--text-title-weight-large) var(--text-title-size-large) / var(--text-title-lineHeight-large) var(--fontStack-sansSerif);
    --text-display-shorthand: var(--text-display-weight) var(--text-display-size) / var(--text-display-lineHeight) var(--fontStack-sansSerif);
    --control-minTarget-fine: 1rem;
    --control-minTarget-coarse: 2.75rem;
    --control-xsmall-size: 1.5rem;
    --control-xsmall-lineBoxHeight: 1.25rem;
    --control-xsmall-paddingBlock: 0.125rem;
    --control-xsmall-paddingInline-condensed: 0.25rem;
    --control-xsmall-paddingInline-normal: 0.5rem;
    --control-xsmall-paddingInline-spacious: 0.75rem;
    --control-xsmall-gap: 0.25rem;
    --control-small-size: 1.75rem;
    --control-small-lineBoxHeight: 1.25rem;
    --control-small-paddingBlock: 0.25rem;
    --control-small-paddingInline-condensed: 0.5rem;
    --control-small-paddingInline-normal: 0.75rem;
    --control-small-gap: 0.25rem;
    --control-medium-size: 2rem;
    --control-medium-lineBoxHeight: 1.25rem;
    --control-medium-paddingBlock: 0.375rem;
    --control-medium-paddingInline-condensed: 0.5rem;
    --control-medium-paddingInline-normal: 0.75rem;
    --control-medium-paddingInline-spacious: 1rem;
    --control-medium-gap: 0.5rem;
    --control-large-size: 2.5rem;
    --control-large-lineBoxHeight: 1.25rem;
    --control-large-paddingBlock: 0.625rem;
    --control-large-paddingInline-normal: 0.75rem;
    --control-large-paddingInline-spacious: 1rem;
    --control-large-gap: 0.5rem;
    --control-xlarge-size: 3rem;
    --control-xlarge-lineBoxHeight: 1.25rem;
    --control-xlarge-paddingBlock: 0.875rem;
    --control-xlarge-paddingInline-normal: 0.75rem;
    --control-xlarge-paddingInline-spacious: 1rem;
    --control-xlarge-gap: 0.5rem;
    --controlStack-small-gap-condensed: 0.5rem;
    --controlStack-small-gap-spacious: 1rem;
    --controlStack-medium-gap-condensed: 0.5rem;
    --controlStack-medium-gap-spacious: 0.75rem;
    --controlStack-large-gap-auto: 0.5rem;
    --controlStack-large-gap-condensed: 0.5rem;
    --controlStack-large-gap-spacious: 0.75rem;
    --space-xxsmall: 0.125rem;
    --space-xsmall: 0.25rem;
    --space-small: 0.375rem;
    --space-medium: 0.5rem;
    --space-large: 0.75rem;
    --space-xlarge: 1rem;
    --spinner-strokeWidth-default: 0.125rem;
    --spinner-size-small: 1rem;
    --spinner-size-medium: 2rem;
    --spinner-size-large: 4rem;
    --stack-padding-condensed: 0.5rem;
    --stack-padding-normal: 1rem;
    --stack-padding-spacious: 1.5rem;
    --stack-gap-condensed: 0.5rem;
    --stack-gap-normal: 1rem;
    --stack-gap-spacious: 1.5rem;
    --overlay-width-xsmall: 12rem;
    --overlay-width-small: 20rem;
    --overlay-width-medium: 30rem;
    --overlay-width-large: 40rem;
    --overlay-width-xlarge: 60rem;
    --overlay-height-small: 16rem;
    --overlay-height-medium: 20rem;
    --overlay-height-large: 27rem;
    --overlay-height-xlarge: 37.5rem;
    --overlay-padding-normal: 1rem;
    --overlay-padding-condensed: 0.5rem;
    --overlay-paddingBlock-condensed: 0.25rem;
    --overlay-paddingBlock-normal: 0.75rem;
    --overlay-borderRadius: 0.375rem;
    --overlay-offset: 0.25rem;
    --control-minTarget-auto: 1rem;
    --controlStack-small-gap-auto: 0.5rem;
    --controlStack-medium-gap-auto: 0.5rem;
    --breakpoint-xsmall: 20rem;
    --breakpoint-small: 34rem;
    --breakpoint-medium: 48rem;
    --breakpoint-large: 63.25rem;
    --breakpoint-xlarge: 80rem;
    --breakpoint-xxlarge: 87.5rem;
    --boxShadow-thin: inset 0 0 0 max(1px, 0.0625rem);
    --boxShadow-thick: inset 0 0 0 max(2px, 0.125rem);
    --boxShadow-thicker: inset 0 0 0 max(4px, 0.25rem);
    --borderWidth-thin: max(1px, 0.0625rem);
    --borderWidth-thick: max(2px, 0.125rem);
    --borderWidth-thicker: max(4px, 0.25rem);
    --borderRadius-small: 0.1875rem;
    --borderRadius-medium: 0.375rem;
    --borderRadius-large: 0.75rem;
    --borderRadius-full: 624.9375rem;
    --outline-focus-offset: -0.125rem;
    --outline-focus-width: 0.125rem;
    --base-text-weight-light: 300;
    --base-text-weight-normal: 400;
    --base-text-weight-medium: 500;
    --base-text-weight-semibold: 600;
    --base-size-4: 0.25rem;
    --base-size-8: 0.5rem;
    --base-size-12: 0.75rem;
    --base-size-16: 1rem;
    --base-size-20: 1.25rem;
    --base-size-24: 1.5rem;
    --base-size-28: 1.75rem;
    --base-size-32: 2rem;
    --base-size-36: 2.25rem;
    --base-size-40: 2.5rem;
    --base-size-44: 2.75rem;
    --base-size-48: 3rem;
    --base-size-64: 4rem;
    --base-size-80: 5rem;
    --base-size-96: 6rem;
    --base-size-112: 7rem;
    --base-size-128: 8rem;
    --topicTag-borderColor: #ffffff00;
    --highlight-neutral-bgColor: #fff8c5;
    --page-header-bgColor: #f6f8fa;
    --diffBlob-addition-fgColor-text: #1f2328;
    --diffBlob-addition-fgColor-num: #1f2328;
    --diffBlob-addition-bgColor-num: #d1f8d9;
    --diffBlob-addition-bgColor-line: #dafbe1;
    --diffBlob-addition-bgColor-word: #aceebb;
    --diffBlob-deletion-fgColor-text: #1f2328;
    --diffBlob-deletion-fgColor-num: #1f2328;
    --diffBlob-deletion-bgColor-num: #ffcecb;
    --diffBlob-deletion-bgColor-line: #ffebe9;
    --diffBlob-deletion-bgColor-word: #ff818266;
    --diffBlob-hunk-bgColor-num: #54aeff66;
    --diffBlob-expander-iconColor: #636c76;
    --codeMirror-fgColor: #1f2328;
    --codeMirror-bgColor: #ffffff;
    --codeMirror-gutters-bgColor: #ffffff;
    --codeMirror-gutterMarker-fgColor-default: #ffffff;
    --codeMirror-gutterMarker-fgColor-muted: #636c76;
    --codeMirror-lineNumber-fgColor: #636c76;
    --codeMirror-cursor-fgColor: #1f2328;
    --codeMirror-selection-bgColor: #54aeff66;
    --codeMirror-activeline-bgColor: #afb8c133;
    --codeMirror-matchingBracket-fgColor: #1f2328;
    --codeMirror-lines-bgColor: #ffffff;
    --codeMirror-syntax-fgColor-comment: #24292f;
    --codeMirror-syntax-fgColor-constant: #0550ae;
    --codeMirror-syntax-fgColor-entity: #8250df;
    --codeMirror-syntax-fgColor-keyword: #cf222e;
    --codeMirror-syntax-fgColor-storage: #cf222e;
    --codeMirror-syntax-fgColor-string: #0a3069;
    --codeMirror-syntax-fgColor-support: #0550ae;
    --codeMirror-syntax-fgColor-variable: #953800;
    --header-fgColor-default: #ffffffb3;
    --header-fgColor-logo: #ffffff;
    --header-bgColor: #24292f;
    --header-borderColor-divider: #57606a;
    --headerSearch-bgColor: #24292f;
    --headerSearch-borderColor: #57606a;
    --data-blue-color: #006edb;
    --data-auburn-color: #9d615c;
    --data-orange-color: #eb670f;
    --data-yellow-color: #b88700;
    --data-green-color: #30a147;
    --data-teal-color: #179b9b;
    --data-purple-color: #894ceb;
    --data-pink-color: #ce2c85;
    --data-red-color: #df0c24;
    --data-gray-color: #808fa3;
    --display-blue-bgColor-muted: #d1f0ff;
    --display-blue-bgColor-emphasis: #006edb;
    --display-blue-fgColor: #005fcc;
    --display-blue-borderColor-muted: #ade1ff;
    --display-blue-borderColor-emphasis: #006edb;
    --display-green-bgColor-muted: #caf7ca;
    --display-green-bgColor-emphasis: #2c8141;
    --display-green-fgColor: #2b6e3f;
    --display-green-borderColor-muted: #9ceda0;
    --display-green-borderColor-emphasis: #2c8141;
    --display-orange-bgColor-muted: #ffe7d1;
    --display-orange-bgColor-emphasis: #b8500f;
    --display-orange-fgColor: #a24610;
    --display-orange-borderColor-muted: #fecfaa;
    --display-orange-borderColor-emphasis: #b8500f;
    --display-purple-bgColor-muted: #f1e5ff;
    --display-purple-bgColor-emphasis: #894ceb;
    --display-purple-fgColor: #783ae4;
    --display-purple-borderColor-muted: #e6d2fe;
    --display-purple-borderColor-emphasis: #894ceb;
    --display-red-bgColor-muted: #ffe2e0;
    --display-red-bgColor-emphasis: #df0c24;
    --display-red-fgColor: #c50d28;
    --display-red-borderColor-muted: #fecdcd;
    --display-red-borderColor-emphasis: #df0c24;
    --display-yellow-bgColor-muted: #ffec9e;
    --display-yellow-bgColor-emphasis: #946a00;
    --display-yellow-fgColor: #805900;
    --display-yellow-borderColor-muted: #ffd642;
    --display-yellow-borderColor-emphasis: #946a00;
    --display-gray-bgColor-muted: #e8ecf2;
    --display-gray-bgColor-emphasis: #647182;
    --display-gray-fgColor: #5c6570;
    --display-gray-borderColor-muted: #d2dae4;
    --display-gray-borderColor-emphasis: #647182;
    --display-auburn-bgColor-muted: #f2e9e9;
    --display-auburn-bgColor-emphasis: #9d615c;
    --display-auburn-fgColor: #8a5551;
    --display-auburn-borderColor-muted: #e6d6d5;
    --display-auburn-borderColor-emphasis: #9d615c;
    --display-brown-bgColor-muted: #eeeae2;
    --display-brown-bgColor-emphasis: #856d4c;
    --display-brown-fgColor: #755f43;
    --display-brown-borderColor-muted: #dfd7c8;
    --display-brown-borderColor-emphasis: #856d4c;
    --display-lemon-bgColor-muted: #f7eea1;
    --display-lemon-bgColor-emphasis: #866e04;
    --display-lemon-fgColor: #786002;
    --display-lemon-borderColor-muted: #f0db3d;
    --display-lemon-borderColor-emphasis: #866e04;
    --display-olive-bgColor-muted: #f0f0ad;
    --display-olive-bgColor-emphasis: #64762d;
    --display-olive-fgColor: #56682c;
    --display-olive-borderColor-muted: #dbe170;
    --display-olive-borderColor-emphasis: #64762d;
    --display-lime-bgColor-muted: #e3f2b5;
    --display-lime-bgColor-emphasis: #527a29;
    --display-lime-fgColor: #476c28;
    --display-lime-borderColor-muted: #c7e580;
    --display-lime-borderColor-emphasis: #527a29;
    --display-pine-bgColor-muted: #bff8db;
    --display-pine-bgColor-emphasis: #167e53;
    --display-pine-fgColor: #156f4b;
    --display-pine-borderColor-muted: #80efb9;
    --display-pine-borderColor-emphasis: #167e53;
    --display-teal-bgColor-muted: #c7f5ef;
    --display-teal-bgColor-emphasis: #127e81;
    --display-teal-fgColor: #106e75;
    --display-teal-borderColor-muted: #89ebe1;
    --display-teal-borderColor-emphasis: #127e81;
    --display-cyan-bgColor-muted: #bdf4ff;
    --display-cyan-bgColor-emphasis: #007b94;
    --display-cyan-fgColor: #006a80;
    --display-cyan-borderColor-muted: #7ae9ff;
    --display-cyan-borderColor-emphasis: #007b94;
    --display-indigo-bgColor-muted: #e5e9ff;
    --display-indigo-bgColor-emphasis: #5a61e7;
    --display-indigo-fgColor: #494edf;
    --display-indigo-borderColor-muted: #d2d7fe;
    --display-indigo-borderColor-emphasis: #5a61e7;
    --avatar-bgColor: #ffffff;
    --avatar-borderColor: #1f232826;
    --avatar-shadow: 0px 0px 0px 2px #ffffffcc;
    --avatarStack-fade-bgColor-default: #afb8c1;
    --avatarStack-fade-bgColor-muted: #d0d7de;
    --control-bgColor-rest: #f6f8fa;
    --control-bgColor-hover: #eef1f4;
    --control-bgColor-active: #e7ebef;
    --control-bgColor-disabled: #eaeef2b3;
    --control-bgColor-selected: #f6f8fa;
    --control-fgColor-rest: #24292f;
    --control-fgColor-placeholder: #6e7781;
    --control-fgColor-disabled: #8c959f;
    --control-borderColor-rest: #d0d7de;
    --control-borderColor-emphasis: #868f99;
    --control-borderColor-disabled: #eaeef2b3;
    --control-borderColor-selected: #f6f8fa;
    --control-borderColor-success: #1a7f37;
    --control-borderColor-danger: #cf222e;
    --control-borderColor-warning: #bf8700;
    --control-iconColor-rest: #636c76;
    --control-transparent-bgColor-rest: #ffffff00;
    --control-transparent-bgColor-hover: #d0d7de33;
    --control-transparent-bgColor-active: #d0d7de66;
    --control-transparent-bgColor-disabled: #eaeef2b3;
    --control-transparent-bgColor-selected: #d0d7de33;
    --control-transparent-borderColor-rest: #ffffff00;
    --control-transparent-borderColor-hover: #ffffff00;
    --control-transparent-borderColor-active: #ffffff00;
    --control-danger-fgColor-rest: #d1242f;
    --control-danger-fgColor-hover: #d1242f;
    --control-danger-bgColor-hover: #ffebe9;
    --control-danger-bgColor-active: #ffebe966;
    --control-checked-bgColor-rest: #0969da;
    --control-checked-bgColor-hover: #0860ca;
    --control-checked-bgColor-active: #0757ba;
    --control-checked-bgColor-disabled: #8c959f;
    --control-checked-fgColor-rest: #ffffff;
    --control-checked-fgColor-disabled: #ffffff;
    --control-checked-borderColor-rest: #0969da;
    --control-checked-borderColor-hover: #0860ca;
    --control-checked-borderColor-active: #0757ba;
    --control-checked-borderColor-disabled: #8c959f;
    --controlTrack-bgColor-rest: #eaeef2;
    --controlTrack-bgColor-hover: #dee3e8;
    --controlTrack-bgColor-active: #d2d8de;
    --controlTrack-bgColor-disabled: #8c959f;
    --controlTrack-fgColor-rest: #636c76;
    --controlTrack-fgColor-disabled: #ffffff;
    --controlTrack-borderColor-rest: #ffffff00;
    --controlTrack-borderColor-disabled: #8c959f;
    --controlKnob-bgColor-rest: #ffffff;
    --controlKnob-bgColor-disabled: #eaeef2b3;
    --controlKnob-bgColor-checked: #ffffff;
    --controlKnob-borderColor-rest: #868f99;
    --controlKnob-borderColor-disabled: #eaeef2b3;
    --controlKnob-borderColor-checked: #0969da;
    --counter-borderColor: #ffffff00;
    --button-default-fgColor-rest: #24292f;
    --button-default-bgColor-rest: #f6f8fa;
    --button-default-bgColor-hover: #eef1f4;
    --button-default-bgColor-active: #e7ebef;
    --button-default-bgColor-selected: #e7ebef;
    --button-default-bgColor-disabled: #eaeef2b3;
    --button-default-borderColor-rest: #d0d7de;
    --button-default-borderColor-hover: #d0d7de;
    --button-default-borderColor-active: #d0d7de;
    --button-default-borderColor-disabled: #eaeef2b3;
    --button-default-shadow-resting: 0px 1px 0px 0px #1f23280a;
    --button-primary-fgColor-rest: #ffffff;
    --button-primary-fgColor-disabled: #ffffffcc;
    --button-primary-iconColor-rest: #ffffffcc;
    --button-primary-bgColor-rest: #1f883d;
    --button-primary-bgColor-hover: #1c8139;
    --button-primary-bgColor-active: #197935;
    --button-primary-bgColor-disabled: #95d8a6;
    --button-primary-borderColor-rest: #1f232826;
    --button-primary-borderColor-hover: #1f232826;
    --button-primary-borderColor-active: #1f232826;
    --button-primary-borderColor-disabled: #95d8a6;
    --button-primary-shadow-selected: inset 0px 1px 0px 0px #002d114d;
    --button-invisible-fgColor-rest: #0969da;
    --button-invisible-fgColor-hover: #0969da;
    --button-invisible-fgColor-disabled: #8c959f;
    --button-invisible-iconColor-rest: #636c76;
    --button-invisible-iconColor-hover: #636c76;
    --button-invisible-iconColor-disabled: #8c959f;
    --button-invisible-bgColor-rest: #ffffff00;
    --button-invisible-bgColor-hover: #d0d7de33;
    --button-invisible-bgColor-active: #d0d7de66;
    --button-invisible-bgColor-disabled: #eaeef2b3;
    --button-invisible-borderColor-rest: #ffffff00;
    --button-invisible-borderColor-hover: #ffffff00;
    --button-invisible-borderColor-disabled: #eaeef2b3;
    --button-outline-fgColor-rest: #0969da;
    --button-outline-fgColor-hover: #ffffff;
    --button-outline-fgColor-active: #ffffff;
    --button-outline-fgColor-disabled: #0969da80;
    --button-outline-bgColor-rest: #f6f8fa;
    --button-outline-bgColor-hover: #0969da;
    --button-outline-bgColor-active: #0757ba;
    --button-outline-bgColor-disabled: #f6f8fa;
    --button-outline-borderColor-hover: #1f232826;
    --button-outline-borderColor-active: #1f232826;
    --button-outline-shadow-selected: inset 0px 1px 0px 0px #00215533;
    --button-danger-fgColor-rest: #d1242f;
    --button-danger-fgColor-hover: #ffffff;
    --button-danger-fgColor-active: #ffffff;
    --button-danger-fgColor-disabled: #d1242f80;
    --button-danger-iconColor-rest: #d1242f;
    --button-danger-iconColor-hover: #ffffff;
    --button-danger-bgColor-rest: #f6f8fa;
    --button-danger-bgColor-hover: #a40e26;
    --button-danger-bgColor-active: #8b0820;
    --button-danger-bgColor-disabled: #eaeef2b3;
    --button-danger-borderColor-rest: #d0d7de;
    --button-danger-borderColor-hover: #1f232826;
    --button-danger-borderColor-active: #1f232826;
    --button-danger-shadow-selected: inset 0px 1px 0px 0px #4c001433;
    --button-inactive-fgColor: #57606a;
    --button-inactive-bgColor: #eaeef2;
    --button-star-iconColor: #eac54f;
    --buttonCounter-default-bgColor-rest: #afb8c133;
    --buttonCounter-invisible-bgColor-rest: #afb8c133;
    --buttonCounter-primary-bgColor-rest: #002d1133;
    --buttonCounter-outline-bgColor-rest: #0969da1a;
    --buttonCounter-outline-bgColor-hover: #ffffff33;
    --buttonCounter-outline-bgColor-disabled: #0969da0d;
    --buttonCounter-outline-fgColor-rest: #0550ae;
    --buttonCounter-outline-fgColor-hover: #ffffff;
    --buttonCounter-outline-fgColor-disabled: #0969da80;
    --buttonCounter-danger-bgColor-hover: #ffffff33;
    --buttonCounter-danger-bgColor-disabled: #cf222e0d;
    --buttonCounter-danger-bgColor-rest: #cf222e1a;
    --buttonCounter-danger-fgColor-rest: #c21c2c;
    --buttonCounter-danger-fgColor-hover: #ffffff;
    --buttonCounter-danger-fgColor-disabled: #d1242f80;
    --focus-outlineColor: #0969da;
    --focus-outline: #0969da solid 2px;
    --menu-bgColor-active: #ffffff00;
    --overlay-bgColor: #ffffff;
    --overlay-borderColor: #d0d7de80;
    --overlay-backdrop-bgColor: #8c959f33;
    --selectMenu-borderColor: #ffffff00;
    --selectMenu-bgColor-active: #b6e3ff;
    --sideNav-bgColor-selected: #ffffff;
    --skeletonLoader-bgColor: #f6f8fa;
    --timelineBadge-bgColor: #eaeef2;
    --treeViewItem-leadingVisual-iconColor-rest: #54aeff;
    --underlineNav-borderColor-active: #fd8c73;
    --underlineNav-borderColor-hover: #afb8c133;
    --underlineNav-iconColor-rest: #636c76;
    --selection-bgColor: #0969da33;
    --fgColor-default: #1f2328;
    --fgColor-muted: #636c76;
    --fgColor-onEmphasis: #ffffff;
    --fgColor-white: #ffffff;
    --fgColor-black: #1f2328;
    --fgColor-disabled: #8c959f;
    --fgColor-link: #0969da;
    --fgColor-neutral: #6e7781;
    --fgColor-accent: #0969da;
    --fgColor-success: #1a7f37;
    --fgColor-attention: #9a6700;
    --fgColor-severe: #bc4c00;
    --fgColor-danger: #d1242f;
    --fgColor-open: #1a7f37;
    --fgColor-closed: #d1242f;
    --fgColor-done: #8250df;
    --fgColor-sponsors: #bf3989;
    --bgColor-default: #ffffff;
    --bgColor-muted: #f6f8fa;
    --bgColor-inset: #f6f8fa;
    --bgColor-emphasis: #24292f;
    --bgColor-inverse: #24292f;
    --bgColor-white: #ffffff;
    --bgColor-black: #1f2328;
    --bgColor-disabled: #eaeef2b3;
    --bgColor-transparent: #ffffff00;
    --bgColor-neutral-muted: #afb8c133;
    --bgColor-neutral-emphasis: #6e7781;
    --bgColor-accent-muted: #ddf4ff;
    --bgColor-accent-emphasis: #0969da;
    --bgColor-success-muted: #dafbe1;
    --bgColor-success-emphasis: #1f883d;
    --bgColor-attention-muted: #fff8c5;
    --bgColor-attention-emphasis: #9a6700;
    --bgColor-severe-muted: #fff1e5;
    --bgColor-severe-emphasis: #bc4c00;
    --bgColor-danger-muted: #ffebe9;
    --bgColor-danger-emphasis: #cf222e;
    --bgColor-open-muted: #dafbe1;
    --bgColor-open-emphasis: #1f883d;
    --bgColor-closed-muted: #ffebe9;
    --bgColor-closed-emphasis: #cf222e;
    --bgColor-done-muted: #fbefff;
    --bgColor-done-emphasis: #8250df;
    --bgColor-sponsors-muted: #ffeff7;
    --bgColor-sponsors-emphasis: #bf3989;
    --borderColor-default: #d0d7de;
    --borderColor-muted: #d0d7deb3;
    --borderColor-emphasis: #6e7781;
    --borderColor-disabled: #eaeef2b3;
    --borderColor-transparent: #ffffff00;
    --borderColor-neutral-muted: #afb8c133;
    --borderColor-neutral-emphasis: #6e7781;
    --borderColor-accent-muted: #54aeff66;
    --borderColor-accent-emphasis: #0969da;
    --borderColor-success-muted: #4ac26b66;
    --borderColor-success-emphasis: #1a7f37;
    --borderColor-attention-muted: #d4a72c66;
    --borderColor-attention-emphasis: #bf8700;
    --borderColor-severe-muted: #fb8f4466;
    --borderColor-severe-emphasis: #bc4c00;
    --borderColor-danger-muted: #ff818266;
    --borderColor-danger-emphasis: #cf222e;
    --borderColor-open-muted: #4ac26b66;
    --borderColor-open-emphasis: #1a7f37;
    --borderColor-closed-muted: #ff818266;
    --borderColor-closed-emphasis: #cf222e;
    --borderColor-done-muted: #c297ff66;
    --borderColor-done-emphasis: #8250df;
    --borderColor-sponsors-muted: #ff80c866;
    --borderColor-sponsors-emphasis: #bf3989;
    --color-ansi-black: #24292f;
    --color-ansi-black-bright: #57606a;
    --color-ansi-white: #6e7781;
    --color-ansi-white-bright: #8c959f;
    --color-ansi-gray: #6e7781;
    --color-ansi-red: #cf222e;
    --color-ansi-red-bright: #a40e26;
    --color-ansi-green: #116329;
    --color-ansi-green-bright: #1a7f37;
    --color-ansi-yellow: #4d2d00;
    --color-ansi-yellow-bright: #633c01;
    --color-ansi-blue: #0969da;
    --color-ansi-blue-bright: #218bff;
    --color-ansi-magenta: #8250df;
    --color-ansi-magenta-bright: #a475f9;
    --color-ansi-cyan: #1b7c83;
    --color-ansi-cyan-bright: #3192aa;
    --color-prettylights-syntax-comment: #57606a;
    --color-prettylights-syntax-constant: #0550ae;
    --color-prettylights-syntax-constant-other-reference-link: #0a3069;
    --color-prettylights-syntax-entity: #6639ba;
    --color-prettylights-syntax-storage-modifier-import: #24292f;
    --color-prettylights-syntax-entity-tag: #0550ae;
    --color-prettylights-syntax-keyword: #cf222e;
    --color-prettylights-syntax-string: #0a3069;
    --color-prettylights-syntax-variable: #953800;
    --color-prettylights-syntax-brackethighlighter-unmatched: #82071e;
    --color-prettylights-syntax-brackethighlighter-angle: #57606a;
    --color-prettylights-syntax-invalid-illegal-text: #f6f8fa;
    --color-prettylights-syntax-invalid-illegal-bg: #82071e;
    --color-prettylights-syntax-carriage-return-text: #f6f8fa;
    --color-prettylights-syntax-carriage-return-bg: #cf222e;
    --color-prettylights-syntax-string-regexp: #116329;
    --color-prettylights-syntax-markup-list: #3b2300;
    --color-prettylights-syntax-markup-heading: #0550ae;
    --color-prettylights-syntax-markup-italic: #24292f;
    --color-prettylights-syntax-markup-bold: #24292f;
    --color-prettylights-syntax-markup-deleted-text: #82071e;
    --color-prettylights-syntax-markup-deleted-bg: #ffebe9;
    --color-prettylights-syntax-markup-inserted-text: #116329;
    --color-prettylights-syntax-markup-inserted-bg: #dafbe1;
    --color-prettylights-syntax-markup-changed-text: #953800;
    --color-prettylights-syntax-markup-changed-bg: #ffd8b5;
    --color-prettylights-syntax-markup-ignored-text: #eaeef2;
    --color-prettylights-syntax-markup-ignored-bg: #0550ae;
    --color-prettylights-syntax-meta-diff-range: #8250df;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #8c959f;
    --color-scale-black: #1f2328;
    --color-scale-transparent: #ffffff00;
    --color-scale-white: #ffffff;
    --color-scale-gray-0: #f6f8fa;
    --color-scale-gray-1: #eaeef2;
    --color-scale-gray-2: #d0d7de;
    --color-scale-gray-3: #afb8c1;
    --color-scale-gray-4: #8c959f;
    --color-scale-gray-5: #6e7781;
    --color-scale-gray-6: #57606a;
    --color-scale-gray-7: #424a53;
    --color-scale-gray-8: #32383f;
    --color-scale-gray-9: #24292f;
    --color-scale-blue-0: #ddf4ff;
    --color-scale-blue-1: #b6e3ff;
    --color-scale-blue-2: #80ccff;
    --color-scale-blue-3: #54aeff;
    --color-scale-blue-4: #218bff;
    --color-scale-blue-5: #0969da;
    --color-scale-blue-6: #0550ae;
    --color-scale-blue-7: #033d8b;
    --color-scale-blue-8: #0a3069;
    --color-scale-blue-9: #002155;
    --color-scale-green-0: #dafbe1;
    --color-scale-green-1: #aceebb;
    --color-scale-green-2: #6fdd8b;
    --color-scale-green-3: #4ac26b;
    --color-scale-green-4: #2da44e;
    --color-scale-green-5: #1a7f37;
    --color-scale-green-6: #116329;
    --color-scale-green-7: #044f1e;
    --color-scale-green-8: #003d16;
    --color-scale-green-9: #002d11;
    --color-scale-yellow-0: #fff8c5;
    --color-scale-yellow-1: #fae17d;
    --color-scale-yellow-2: #eac54f;
    --color-scale-yellow-3: #d4a72c;
    --color-scale-yellow-4: #bf8700;
    --color-scale-yellow-5: #9a6700;
    --color-scale-yellow-6: #7d4e00;
    --color-scale-yellow-7: #633c01;
    --color-scale-yellow-8: #4d2d00;
    --color-scale-yellow-9: #3b2300;
    --color-scale-orange-0: #fff1e5;
    --color-scale-orange-1: #ffd8b5;
    --color-scale-orange-2: #ffb77c;
    --color-scale-orange-3: #fb8f44;
    --color-scale-orange-4: #e16f24;
    --color-scale-orange-5: #bc4c00;
    --color-scale-orange-6: #953800;
    --color-scale-orange-7: #762c00;
    --color-scale-orange-8: #5c2200;
    --color-scale-orange-9: #471700;
    --color-scale-red-0: #ffebe9;
    --color-scale-red-1: #ffcecb;
    --color-scale-red-2: #ffaba8;
    --color-scale-red-3: #ff8182;
    --color-scale-red-4: #fa4549;
    --color-scale-red-5: #cf222e;
    --color-scale-red-6: #a40e26;
    --color-scale-red-7: #82071e;
    --color-scale-red-8: #660018;
    --color-scale-red-9: #4c0014;
    --color-scale-purple-0: #fbefff;
    --color-scale-purple-1: #ecd8ff;
    --color-scale-purple-2: #d8b9ff;
    --color-scale-purple-3: #c297ff;
    --color-scale-purple-4: #a475f9;
    --color-scale-purple-5: #8250df;
    --color-scale-purple-6: #6639ba;
    --color-scale-purple-7: #512a97;
    --color-scale-purple-8: #3e1f79;
    --color-scale-purple-9: #2e1461;
    --color-scale-pink-0: #ffeff7;
    --color-scale-pink-1: #ffd3eb;
    --color-scale-pink-2: #ffadda;
    --color-scale-pink-3: #ff80c8;
    --color-scale-pink-4: #e85aad;
    --color-scale-pink-5: #bf3989;
    --color-scale-pink-6: #99286e;
    --color-scale-pink-7: #772057;
    --color-scale-pink-8: #611347;
    --color-scale-pink-9: #4d0336;
    --color-scale-coral-0: #fff0eb;
    --color-scale-coral-1: #ffd6cc;
    --color-scale-coral-2: #ffb4a1;
    --color-scale-coral-3: #fd8c73;
    --color-scale-coral-4: #ec6547;
    --color-scale-coral-5: #c4432b;
    --color-scale-coral-6: #9e2f1c;
    --color-scale-coral-7: #801f0f;
    --color-scale-coral-8: #691105;
    --color-scale-coral-9: #510901;
    --shadow-inset: inset 0px 1px 0px 0px #1f23280a;
    --shadow-resting-xsmall: 0px 1px 0px 0px #1f23281a;
    --shadow-resting-small: 0px 1px 0px 0px #1f23280a;
    --shadow-resting-medium: 0px 3px 6px 0px #424a531f;
    --shadow-floating-small: 0px 0px 0px 1px #d0d7de80, 0px 6px 12px -3px #424a530a, 0px 6px 18px 0px #424a531f;
    --shadow-floating-medium: 0px 0px 0px 1px #d0d7de, 0px 8px 16px -4px #424a5314, 0px 4px 32px -4px #424a5314, 0px 24px 48px -12px #424a5314, 0px 48px 96px -24px #424a5314;
    --shadow-floating-large: 0px 0px 0px 1px #d0d7de, 0px 40px 80px 0px #424a533d;
    --shadow-floating-xlarge: 0px 0px 0px 1px #d0d7de, 0px 56px 112px 0px #424a5352;
    --shadow-floating-legacy: 0px 6px 12px -3px #424a530a, 0px 6px 18px 0px #424a531f;
}

div {
    display: block;
}

.App {
    display: block;
    word-wrap: break-word;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: var(--body-font-size, 14px);
    line-height: 1.5;
    color: var(--fgColor-default, var(--color-fg-default));
}

#header {
    padding-top: var(--base-size-16, 16px) !important;
    display: block;
    --dialog-scrollgutter: 17px;
    overflow-wrap: break-word;
}

#headerContainer {
    gap: 1rem;
    display: flex !important;
    padding-right: var(--base-size-16, 16px) !important;
    padding-left: var(--base-size-16, 16px) !important;
    margin-bottom: var(--base-size-16, 16px) !important;
    justify-content: flex-end !important;    flex-wrap: wrap !important;
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
    word-wrap: break-word;
    overflow-wrap: break-word;
    --dialog-scrollgutter: 17px;
}

@media (min-width: 1012px) {
    #headerContainer {
        padding-right: var(--base-size-32, 32px) !important;
        padding-left: var(--base-size-32, 32px) !important;
    }
}

@media (min-width: 768px) {
    #headerContainer {
        padding-right: var(--base-size-24, 24px) !important;
        padding-left: var(--base-size-24, 24px) !important;
    }
}

#headerHr {
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
}

#titleContainer {
    margin-right: var(--base-size-16, 16px) !important;
    min-width: 0 !important;
    max-width: 100% !important;
    flex: auto !important;
}

#titleComponent {
    display: flex !important;
    word-break: break-word !important;
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
    font-weight: var(--base-text-weight-normal, 400) !important;
    font-size: var(--h3-size-mobile, 18px) !important;
    align-items: center !important;
    flex-wrap: wrap !important;
}

@media (min-width: 768px) {
    #titleComponent {
        font-size: var(--h3-size, 20px) !important;
    }
}

#titleImage {
    background-color: var(--avatar-bgColor, var(--color-avatar-bg));
    border-radius: var(--borderRadius-medium, 0.375rem);
    box-shadow: 0 0 0 1px var(--avatar-borderColor, var(--color-avatar-border));
    display: inline-block;
    flex-shrink: 0;
    line-height: 1;
    overflow: hidden;
    vertical-align: middle;
    /* display: none !important; */
    margin-right: var(--base-size-8, 8px) !important;
    border-style: none;
    width: 24px;
    aspect-ratio: auto 24 / 24;
    height: 24px;
    overflow-clip-margin: content-box;
}

@media (min-width: 768px) {
    #titleImage {    
        display: block !important;
    }
}

#titleStrong {
    /* display: none !important; */
    margin-right: var(--base-size-8, 8px) !important;
    align-self: stretch !important;
    font-weight: var(--base-text-weight-semibold, 600);
}

@media (min-width: 768px) {
    #titleStrong {    
        display: block !important;
    }
}

#title {
    color: var(--fgColor-default, var(--color-fg-default)) !important;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
}

#title:hover {
    text-decoration-line: underline;
}

#titlePublic {
    border-color: var(--borderColor-default, var(--color-border-default));
    color: var(--fgColor-muted, var(--color-fg-muted));
    border: var(--borderWidth-thin, max(1px, 0.0625rem)) solid var(--borderColor-default, var(--color-border-default));
    border-radius: 2em;
    font-size: var(--text-body-size-small, 0.75rem);
    font-weight: var(--base-text-weight-medium, 500);
    line-height: 18px;
    padding: 0 7px;
    white-space: nowrap;
    /* display: none !important; */
    margin-right: var(--base-size-4, 4px) !important;
    vertical-align: middle !important;
}

@media (min-width: 768px) {
    #titlePublic {
        display: block !important;
    }
}

#detailsContainer {
    --dialog-scrollgutter: 17px;
    word-wrap: break-word;
}

#detailsUl {
    display: none !important;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    word-wrap: break-word;
    --dialog-scrollgutter: 17px;
}

@media (min-width: 768px) {
    #detailsUl {
        display: inline !important;
    }
}

.detailsLi {
    float: left;
    margin: 0 8px 0 0;
    font-size: 12px;
    color: var(--fgColor-default, var(--color-fg-default));
    list-style-type: none;
    display: list-item;
    text-align: -webkit-match-parent;
    --dialog-scrollgutter: 17px;
    word-wrap: break-word;
}

.detailsLi svg {
    display: inline-block;
    user-select: none;
    vertical-align: text-bottom;
    overflow: visible;
    margin-right: 10px;
}

.detailsLi a {
    display: block;
    text-align: center;
    margin: .25rem;
    padding: 0 1rem;
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
    border: 1.5px solid grey;
    border-radius: 4px;
    color: var(--button-default-fgColor-rest, var(--color-btn-text, #24292f));
    background-color: var(--button-default-bgColor-rest, var(--color-btn-bg, #f6f8fa));
    border-color: var(--button-default-borderColor-rest, var(--button-default-borderColor-rest, var(--color-btn-border, rgba(31, 35, 40, 0.15))));

}
  
.detailsLi a:hover {
    background-color: var(--button-default-bgColor-hover);
}

#content {
    padding-right: var(--base-size-16, 16px) !important;
    padding-left: var(--base-size-16, 16px) !important;
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
    --dialog-scrollgutter: 17px;
    word-wrap: break-word;

}

@media (min-width: 1012px) {
    #content {
        padding-right: var(--base-size-32, 32px) !important;
        padding-left: var(--base-size-32, 32px) !important;
    }
}

@media (min-width: 768px) {
    #content {
        padding-right: var(--base-size-24, 24px) !important;
        padding-left: var(--base-size-24, 24px) !important;
    }
}

#contentGrid {
    max-width: 100%;
    /* grid-template-columns: minmax(0, calc(100% - var(--Layout-sidebar-width) - var(--Layout-gutter))) 0 auto;   display: grid; */
    --Layout-sidebar-width: 220px;
    --Layout-gutter: 16px;
    grid-auto-flow: column;
    grid-template-columns: auto 0 minmax(0, calc(100% - var(--Layout-sidebar-width) - var(--Layout-gutter)));
    grid-gap: var(--Layout-gutter);
    word-wrap: break-word;
    --dialog-scrollgutter: 17px;
}

@media (min-width: 544px) {
    #contentGrid {
        --Layout-sidebar-width: 220px;
        grid-template-columns: auto 0 minmax(0, calc(100% - var(--Layout-sidebar-width) - var(--Layout-gutter)));

    }
}

@media (min-width: 768px) {
    #contentGrid {
        --Layout-sidebar-width: 256px;
        grid-template-columns: minmax(0, calc(100% - var(--Layout-sidebar-width) - var(--Layout-gutter))) 0 auto;   display: grid;

    }
}

@media (min-width: 1012px) {
    #contentGrid {
        --Layout-sidebar-width: 296px;
        --Layout-gutter: 24px;
        grid-template-columns: minmax(0, calc(100% - var(--Layout-sidebar-width) - var(--Layout-gutter))) 0 auto;   display: grid;

    }
}

@media screen and (min-width: 1280px) {
    #contentGrid {
        margin-right: calc((100% - 1356px) / 2);
        /* grid-template-columns: minmax(0, calc(100% - var(--Layout-sidebar-width) - var(--Layout-gutter))) 0 auto;   display: grid; */

    }
}

#mainLayout {
    grid-column: 1;
    min-width: 0;
}

#mainBox {
    margin-top: 16px;
    margin-bottom: 16px;
}

#mainHeader {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 1;
    padding-bottom: 16px;
    padding-top: 8px;
}

#mainHeaderLeft {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

#mainHeaderRight {
    display: flex;
    padding-left: 8px;
    gap: 8px;
}

#mainHeader a {
    display: block;
    text-align: center;
    margin: .25rem;
    padding: .25rem 1rem;
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
    border: 1.5px solid grey;
    border-radius: 4px;
    color: var(--button-default-fgColor-rest, var(--color-btn-text, #24292f));
    border-color: var(--button-default-borderColor-rest, var(--button-default-borderColor-rest, var(--color-btn-border, rgba(31, 35, 40, 0.15))));
    background-color: var(--button-default-bgColor-rest, var(--color-btn-bg, #f6f8fa));
}

#mainHeader svg {
    display: inline-block;
    user-select: none;
    vertical-align: text-bottom;
    overflow: visible;
    margin-right: 10px;
}

#mainHeader a:hover {
    background-color: var(--button-default-bgColor-hover);
}

#mainHeader #mainHeaderRight a {
    color: var(--button-primary-fgColor-rest, var(--color-btn-text, #24292f));
    border-color: var(--button-primary-bgColor-rest, var(--button-primary-borderColor-rest, var(--color-btn-border, rgba(31, 35, 40, 0.15))));
    background-color: var(--button-primary-bgColor-rest, var(--color-btn-bg, #f6f8fa));
}

#mainHeader #mainHeaderRight a:hover {
    background-color: var(--button-primary-bgColor-hover);
}


#mainTable {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px;
    border-width: 1px;
    border-style: solid;
    border-image: initial;
    border-color: var(--borderColor-default, var(--color-border-default, #d0d7de));
    table-layout: fixed;
    border-radius: 6px;
    overflow: unset;
}

#mainTbody {
    display: table-row-group;
    vertical-align: middle;
    unicode-bidi: isolate;
    border-color: inherit;
}

tr {
    display: table-row;
    vertical-align: inherit;
    unicode-bidi: isolate;
    border-color: inherit;
}

td {
    display: table-cell;
    vertical-align: inherit;
    unicode-bidi: isolate;
}

#mainHeadTr {
    color: var(--fgColor-muted, var(--color-fg-muted, #656d76));
    font-size: 12px;
    height: 40px;
}

#mainHeadTr img {
    display: inline-block;
    overflow: hidden;
    line-height: 1;
    vertical-align: middle;
    border-radius: 50%;
    box-shadow: 0 0 0 1px var(--avatar-borderColor, var(--color-avatar-border, rgba(31, 35, 40, 0.15)));
    height: var(--avatar-size);
    width: var(--avatar-size);
    --avatar-size: 20px;
    margin-right: 8px;
    margin-top: -1px;
    margin-left: 1px;
}

#mainHeadTr span {
    font-weight: 600;
    white-space: nowrap;
    color: var(--fgColor-default, var(--color-fg-default, #1F2328));
    font-size: 14px;
}

#mainHeadTr a {
    color: var(--fgColor-muted, var(--color-fg-muted));
    text-decoration: none;
    white-space: nowrap;
    padding-left: 6px;
}

#mainHeadTr a:hover {
    color: var(--fgColor-accent, var(--color-accent-fg));
    text-decoration-line: underline;
}

#mainHeadTr td {
    background-color: var(--bgColor-muted, var(--color-canvas-subtle, #f6f8fa));
    padding: 4px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding-left: 16px;
    width: auto;
}

.mainTr {
    height: 40px;
    font-size: 14px;
}

.mainTr:hover {
    background-color: var(--bgColor-muted, var(--color-canvas-subtle, #f6f8fa));
}

.mainTr .mainTd1 {
    text-align: left;
    border-top: 1px solid var(--borderColor-default, var(--color-border-default));
    display: table-cell;
    padding: 0;
    padding-left: 16px;
}

.mainTr .mainTd1 svg {
    display: inline-block;
    user-select: none;
    vertical-align: text-bottom;
    overflow: visible;
    padding-right: 16px;  
}

.mainTr .mainTd1 a {
    padding-left: 6px;
    text-decoration: none;
    color: var(--fgColor-default, var(--color-fg-default));
}

.mainTr .mainTd1 a:hover {
    color: var(--fgColor-accent, var(--color-accent-fg));
    text-decoration-line: underline;
}

.mainTr .mainTd2 {
    text-align: left;
    border-top: 1px solid var(--borderColor-default, var(--color-border-default));
    display: table-cell;
    padding: 0;
    padding-left: 16px;
    font-size: 10px;
}

@media (min-width: 768px) {
    .mainTr .mainTd2 {
        font-size: 14px;
    }
}

.mainTr .mainTd3 {
    text-align: right;
    border-top: 1px solid var(--borderColor-default, var(--color-border-default));
    padding: 0;  
    padding-left: 16px;  
    padding-right: 16px;  
    font-size: 10px;
}

@media (min-width: 768px) {
    .mainTr .mainTd3 {
        font-size: 14px;
    }
}

#sidebarLayout {
    grid-column: 2 / span 2;
    width: var(--Layout-sidebar-width);
    /* display: none !important; */
}

#sidebarBox {
    margin-top: 16px;
    margin-bottom: 16px;
}

@media (min-width: 768px) {
    #sidebarLayout {
        /* display: block !important; */
        width: var(--Layout-sidebar-width);
    }
}

.sidebarHr {
    margin-bottom: 15px;
}